<template>
  <div
    class="d-flex container"
    :style="'height: ' + height + 'px; cursor: pointer;'"
    @click="redirect(link)" 
  >
    <!-- $router.push(link)" -->
    <!-- Image -->
    <div
      class="d-flex align-content-start justify-content-start"
      style="width: 30%; height: inherit"
    >
      <v-img
        contain
        :height="height + 10 + 'px'"
        width="inherit"
        :src="getImgSrc()"
        style="transform: translate(-2px, -4px)"
        position="center top"
      ></v-img>
    </div>

    <div style="width: 70%" class="d-flex flex-column">
      <!-- Flag -->
      <div class="d-flex justify-end" style="width: 100%; height: 20%">
        <country-flag
          class="mr-1"
          :country="language"
          size="normal"
        ></country-flag>
      </div>

      <!-- Title -->
      <div
        class="d-flex justify-start align-start pl-5 pr-5"
        style="width: 100%; height: 50%"
      >
        <span style="font-weight: 550; font-size: 1.2rem">{{ name }}</span>
      </div>

      <!-- Buttons -->
      <div
        class="d-flex align-end justify-end"
        style="width: 100%; height: 30%"
      >
        <v-btn
          outlined
          icon
          color="#b31616"
          class="mb-2 mr-2"
          style="background-color: rgba(255, 255, 255, 0.75)"
        >
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag";
export default {
  name: "ResourceCardDocumentation",
  components: {
    CountryFlag,
  },
  props: {
    link: {
      type: String,
      required: true,
    },
    language: {
      type: String,
      required: false,
      default: "gb",
    },
    name: {
      type: String,
      required: true,
    },
    height: {
      type: Number,
      required: false,
      default: 190,
    },
  },
  methods: {
    getImgSrc: function () {
      return require("../assets/placeholder.svg");
    },
    redirect: function () {
      window.open(this.link);
    },
  },
};
</script>

<style scoped>
.imageclass {
  transform: translate(-5px, -5px);
}
.container {
  border-style: solid;
  border-radius: 25px;
  border-width: thin;
  border-color: rgba(0, 0, 0, 0.35);
  padding: 0;
  overflow: hidden;
}
.show {
  border-style: dotted;
  border-width: thin;
  border-color: rgba(255, 0, 0, 0.35);
}
</style>