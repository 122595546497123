<template>
  <v-card
    flat
    class="ml-8 mr-8"
  >
    <v-card-title class="mt-6">
      <menu-title title="Documentations"></menu-title>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="4"
          v-for="(resource, index) in items"
          :key="index"
        >
          <resource-documentation-card
            :name="resource.displayName"
            :language="resource.language"
            :link="resource.link"
          >
          </resource-documentation-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import menuTitle from "../../components/helpers/menu_title.vue";
import resourceDocumentationCard from "../../components/resource_documentation_card.vue";
export default {
  name: "RsourcesDocumentation",
  components: {
    menuTitle,
    resourceDocumentationCard,
  },
  data: () => ({
    items: [
      {
        application: "wms",
        displayName: "COBI.wms Warehouse Management Solution Documentation",
        language: "gb",
        link: "/docs/wms",
      },
      {
        application: "wms",
        displayName: "COBI.wms User manual",
        language: "gb",
        link: "/docs/wms_manual_en",
      },
      {
        application: "wms",
        displayName: "COBI.wms Benutzerhandbuch",
        language: "de",
        link: "/docs/wms_manual_de",
      },
      
      {
        application: "time",
        displayName: "COBI.time Time Recording for Personnel Documentation",
        language: "gb",
        link: "/docs/time",
      },
      {
        application: "time",
        displayName: "COBI.time User manual",
        language: "gb",
        link: "/docs/time_manual_en",
      },
      {
        application: "time",
        displayName: "COBI.time Benutzerhandbuch",
        language: "de",
        link: "/docs/time_manual_de",
      },
      {
        application: "time",
        displayName: "COBI.time Installation Guide",
        language: "gb",
        link: "/docs/time_installation_en",
      },

      {
        application: "ppc",
        displayName: "COBI.ppc Production Planning and Control Documentation",
        language: "gb",
        link: "/docs/ppc",
      },

      /* {
        application: "edi",
        displayName: "COBI.edi Electronic Data Interchange Documentation",
        language: "gb",
        link: "/docs/edi",
      }, */

      {
        application: "msv",
        displayName: "COBI.msv MSV3 version 2 server Documentation",
        language: "gb",
        link: "/docs/msv",
      },
      {
        application: "msv",
        displayName: "COBI.msv Dokumentation",
        language: "de",
        link: "/docs/msv_de",
      },
    ],
  }),
};
</script>

<style>
</style>